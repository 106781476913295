import React from 'react';
import classNames from 'classnames';

export interface DividerProps {
  dividerSize: number;
  cqResponsiveClasses: string[];
}

const Divider = ({ dividerSize, cqResponsiveClasses }: DividerProps) => (
  <div
    data-testid="divider"
    className={classNames(cqResponsiveClasses)}
    style={{ height: dividerSize }}
  />
);

export default Divider;
